import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import cx from 'classnames';
import { trans, getLangFromPath, linkWithLang } from '../utils/i18n';

function getMenuLabel(path) {
  return /^\/en/.test(path) ? 'Open the menu' : 'Ouvrir le menu';
}

export const Header = ({ siteTitle, path: currentPath }) => {
  const lang = getLangFromPath(currentPath);
  const [folded, setfolded] = useState(true);
  const frenchLink = (
    <Link to={currentPath.replace(/\/en\/?/, '/')}>Français</Link>
  );
  const englishLink = <Link to={`/en${currentPath}`}>English</Link>;
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          navLinks {
            path
            label
          }
        }
      }
    }
  `);

  if (!site) return null;
  return (
    <header>
      <section>
        <div className="first-row">
          <h1>
            <Link to={lang === 'fr' ? '/' : '/en'}>{siteTitle}</Link>
          </h1>
          <button
            aria-label={getMenuLabel()}
            onClick={toggleMenu}
            className={cx('hamburger hamburger--slider', {
              'is-active': !folded,
            })}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        {renderLinks(site.siteMetadata)}
      </section>
    </header>
  );

  function toggleMenu() {
    setfolded(!folded);
  }

  function renderLinks({ navLinks }) {
    return (
      <div className={cx('main-nav', { folded })}>
        {navLinks.map(({ path, label }) => {
          const to = linkWithLang(path, currentPath);
          return (
            <Link
              key={to}
              className={cx({ current: currentPath === to })}
              to={to}
            >
              {trans(lang, `navigation.${label}`)}
            </Link>
          );
        })}
        <div className="lang-switch">
          {lang === 'fr' ? englishLink : frenchLink}
        </div>
      </div>
    );
  }
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  path: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  path: ``,
};
