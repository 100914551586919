import React from "react"
import PropTypes from "prop-types"

export const Footer = ({ siteTitle }) => {
  return (
    <footer>
      <section>
        © 1999 - {new Date().getFullYear()}, {siteTitle}
      </section>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
}
