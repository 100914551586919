import fr from '../../config/i18n/fr.json';
import en from '../../config/i18n/en.json';
import get from 'lodash/get';

const map = { fr, en };

export function trans(lang, key) {
  return get(map, `${lang}.${key}`, `[${lang}.${key}]`);
}

export function getLangFromPath(path) {
  return /^\/en/.test(path) ? 'en' : 'fr';
}

export function linkWithLang(link, currentPath) {
  const lang = getLangFromPath(currentPath);
  if (!link) {
    return lang === 'fr' ? `/` : `/${lang}`;
  }
  return lang === 'fr' ? `/${link}` : `/${lang}/${link}`;
}
